import React from 'react';
import './OurTeam.css';
import './Common.css';

const OurTeam: React.FC = () => {
  // Example team members, you can replace these with actual data
  const teamMembers = [
    { name: 'Günter W. Beck'},
    { name: 'Suzanna Biblis', title: ''},
    { name: 'Michelle Schmitt', title: ''  },
    { name: 'Christian Schröder', title: '' },
    { name: 'Suprabhat', title: ''},
    { name: 'Arndt Werner', title: ''},
  ];	
  
  return (
    <div className="our-team-container">
      <h1>ABOUT</h1>
      <p>
        Based at Siegen University, our team of researchers and policy analysts work together to analyze new data and create a platform with a dashboard for local stakeholders to make more informed decisions.
      </p>
      <h2>OUR TEAM</h2>
      <div className="team-section">
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <div className="team-member" key={index}>
              <h3>{member.name}</h3>
              <p>{member.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
