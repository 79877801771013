import React, { useState, useEffect } from 'react';
import './Dashboard.css'; // Import the CSS file for the dashboard
import './Common.css'; // Import the common CSS file
import { useLocation, useNavigate } from 'react-router-dom';

const Dashboard: React.FC = () => {
  const [zoomLevel, setZoomLevel] = useState(1); // State for zoom level
  const navigate = useNavigate();
  const location = useLocation();
  const iframeSrc = location.state?.iframeSrc || '';

  const handleZoomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZoomLevel(parseFloat(event.target.value));
  };

  const handleBackClick = () => {
    navigate('/');
  };

  useEffect(() => {
    if (iframeSrc) {
      document.body.classList.add('hide-nav');
    } else {
      document.body.classList.remove('hide-nav');
    }

    return () => {
      document.body.classList.remove('hide-nav');
    };
  }, [iframeSrc]);

  return (
    <div className="dashboard-page">
      {iframeSrc ? (
        <div className="iframe-container">
          <iframe
            title="RESME Data Visualization"
            className="iframe"
            src={iframeSrc}
            allowFullScreen
            style={{ transform: `scale(${zoomLevel})` }}
          ></iframe>
          <div className="controls">
            <button className="back-button" onClick={handleBackClick}>Back to Homepage</button>
            <div className="zoom-controls">
              <label htmlFor="zoom">Zoom: </label>
              <input
                type="range"
                id="zoom"
                name="zoom"
                min="0.5"
                max="2"
                step="0.1"
                value={zoomLevel}
                onChange={handleZoomChange}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="placeholder">
          No indicator selected.
        </div>
      )}
    </div>
  );
};

export default Dashboard;
