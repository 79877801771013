import React, { useState, useRef } from 'react';
import './ResearchPage.css';

const KnowledgeTransfer: React.FC = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const findingsRef = useRef<HTMLDivElement>(null);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    if (tab === 'findings' && findingsRef.current) {
      findingsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="page-container">
      <h1>Knowledge Transfer</h1>
      <div className="tab-menu">
        <button className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`} onClick={() => handleTabClick('overview')}>
          Overview
        </button>
        <button className={`tab-button ${activeTab === 'findings' ? 'active' : ''}`} onClick={() => handleTabClick('findings')}>
          Findings
        </button>
      </div>

      <div className={`tab-content overview-content ${activeTab === 'overview' ? 'active' : ''}`}>
        <h2>Overview</h2>
        <p className="about-text">How do higher education institutions transfer their knowledge to the regional economy? Exploiting soft information from website texts of higher education institutions, we proxy their transfer activities and aim at enhancing traditional transfer measurements.</p>
        <a href="https://aisel.aisnet.org/icis2023/dab_sc/dab_sc/18/" className="explore-link">Explore our paper</a>
      </div>

      <div ref={findingsRef} className={`tab-content findings-content ${activeTab === 'findings' ? 'active' : ''}`}>
        <h2>Findings</h2>
        <ul className="findings-list">
          <li>Communication patterns of transfer activities of German higher education institutions: Text-based measurement uncovers preference for informal transfer</li>
        </ul>
        <div className="chart-container">
          <img src={`${process.env.PUBLIC_URL}/chart_1.png`} alt="Knowledge Transfer Chart 1" className="chart-image" />
        </div>
      </div>
    </div>
  );
};

export default KnowledgeTransfer;
