import React, { useState, useRef } from 'react';
import './DataPage.css';

const DataPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('overview');

  const overviewRef = useRef<HTMLDivElement>(null);
  const dataSourcesRef = useRef<HTMLDivElement>(null);
  const workflowRef = useRef<HTMLDivElement>(null);
  const availableDataRef = useRef<HTMLDivElement>(null);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    switch (tab) {
      case 'overview':
        overviewRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'data-sources':
        dataSourcesRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'data-workflow':
        workflowRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'available-data':
        availableDataRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  };

  return (
    <div className="data-page">
      <div className="tab-menu">
        <button className={activeTab === 'overview' ? 'active' : ''} onClick={() => handleTabClick('overview')}>Coverage and Usage</button>
        <button className={activeTab === 'data-sources' ? 'active' : ''} onClick={() => handleTabClick('data-sources')}>Data Sources</button>
        <button className={activeTab === 'data-workflow' ? 'active' : ''} onClick={() => handleTabClick('data-workflow')}>Workflow</button>
        <button className={activeTab === 'available-data' ? 'active' : ''} onClick={() => handleTabClick('available-data')}>Available Data</button>
      </div>

      <div ref={overviewRef} className="tab-content overview-content">
        <h2>Coverage and Usage</h2>
        <p>We continuously collect data that provides insights into regional economics and businesses. Please note that data can only be accessed within the RESME network. Please contact us for data access and cooperations.</p>
      </div>

      <div ref={dataSourcesRef} className="tab-content data-sources-content">
        <h2>Data Sources</h2>
        <div className="data-boxes">
          <div className="data-box">
            <h3>Web Data Sources</h3>
            <ul>
              <li>Insolvency announcements</li>
              <li>Websites (Firms, universities, banks)</li>
              <li>Web portals (jobs, real estate)</li>
              <li>Newspages</li>
            </ul>
          </div>
          <div className="data-box">
            <h3>Traditional Data Sources</h3>
            <ul>
              <li>ORBIS – firm-level financial and management data</li>
              <li>Destatis</li>
              <li>Regionalstatistik</li>
            </ul>
          </div>
        </div>
      </div>

      <div ref={workflowRef} className="tab-content workflow-content">
        <h2>Workflow</h2>
        <p>We collect and process data from different sources then link it to generate valuable insights.</p>
        <img src="data-workflow.png" alt="Data Workflow" className="workflow-image" />
      </div>

      <div ref={availableDataRef} className="tab-content available-data-content">
        <h2>Available Data</h2>
        <p>Our data workflow generates unique datasets for regional economic and SME research. We enrich our web scraped data with information from traditional data sources like ORBIS (Bureau van Dijk) and official statistics (Destatis, Eurostat).</p>
        <table className="data-table">
          <tbody>
            <tr>
              <td>Firm websites</td>
              <td>Insolvency data</td>
              <td>Local bank prices</td>
            </tr>
            <tr>
              <td>Job postings</td>
              <td>Real estate prices and rents</td>
              <td>University websites</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataPage;
