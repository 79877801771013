import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ResearchModal.css';

interface ResearchModalProps {
  onClose: () => void;
}

const ResearchModal: React.FC<ResearchModalProps> = ({ onClose }) => {
  const navigate = useNavigate();

  const handleTileClick = (path: string) => {
    onClose();
    navigate(path);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-button" onClick={onClose}>&times;</span>
        <h2>Current Research Areas</h2>
        <div className="research-grid">
          <div className="research-tile" onClick={() => handleTileClick('/knowledge-transfer')}>
            <img src={`${process.env.PUBLIC_URL}/pexels-rdne-5922204.jpg`} alt="Knowledge Transfer" />
            <h3>Knowledge Transfer</h3>
          </div>
          <div className="research-tile" onClick={() => handleTileClick('/real-time-insolvencies')}>
            <img src={`${process.env.PUBLIC_URL}/melinda-gimpel-9j8k3l9afkc-unsplash.jpg`} alt="Real-time Insolvencies" />
            <h3>Anatomy of Real-Time Insolvencies</h3>
          </div>
          <div className="research-tile" onClick={() => handleTileClick('/firm-level-research')}>
            <img src={`${process.env.PUBLIC_URL}/pexels-kevin-ku-92347-577585.jpg`} alt="Firm-level Research" />
            <h3>Using Web-Scraped Data to Measure Firm CSR</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchModal;
