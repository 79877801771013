import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import { FaInfoCircle, FaDatabase, FaResearchgate, FaBalanceScale, FaChartLine, FaSearch, FaBars, FaDollarSign } from 'react-icons/fa';
import Home from './pages/Home';
import OurTeam from './pages/OurTeam';
import Research from './pages/Research';
import KnowledgeTransfer from './pages/KnowledgeTransfer';
import RealTimeInsolvencies from './pages/RealTimeInsolvencies';
import FirmLevelResearch from './pages/FirmLevelResearch';
import DataPage from './pages/DataPage';
import Dashboard from './pages/Dashboard';
import Footer from './components/Footer';
import Search from './components/Search';
import ResearchModal from './components/ResearchModal';
import ScrollToTop from './components/ScrollToTop'; // Ensure this path is correct
import './App.css'; // Import the CSS file

const App: React.FC = () => {
  const [navActive, setNavActive] = useState(false);
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [iframeSrc, setIframeSrc] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeNav = () => {
    setNavActive(false);
  };

  const handleModalChange = (modal: string) => {
    setActiveModal(modal);
    closeNav();
  };

  const handleIndicatorClick = (src: string) => {
    setIframeSrc(src);
    setActiveModal(null);
    navigate('/dashboard', { state: { iframeSrc: src } });
  };

  useEffect(() => {
    closeNav(); // Close modals on route change
  }, [location]);

  return (
    <div className="app-container">
      <ScrollToTop />
      <nav className="nav-bar">
        <Link to="/" className="logo-link" onClick={() => setActiveModal(null)}>
          <img src={`${process.env.PUBLIC_URL}/RESME_logo_blue.PNG`} alt="RESME Logo" className="logo" />
        </Link>
        <FaBars className="hamburger" onClick={toggleNav} /> 
        <ul className={`nav-list ${navActive ? 'active' : ''}`}>
          <li><Link to="/data-page" onClick={() => setActiveModal(null)}><FaDatabase /> DATA</Link></li>
          <li><Link to="#" onClick={() => handleModalChange('dashboard')}><FaChartLine /> DASHBOARDS</Link></li>
          <li><Link to="#" onClick={() => handleModalChange('research')}><FaResearchgate /> RESEARCH</Link></li>
          <li><Link to="/our-team" onClick={() => setActiveModal(null)}><FaInfoCircle /> ABOUT</Link></li>
        </ul>
        <FaSearch className="search-icon" onClick={() => handleModalChange('search')} />
      </nav>

      {activeModal === 'dashboard' && (
        <div className="modal-overlay" onClick={() => setActiveModal(null)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={() => setActiveModal(null)}>&times;</span>
            <h2>Indicators</h2>
            <div className="indicators-grid">
              <div className="indicator-card" onClick={() => handleIndicatorClick('https://mschmitt-resme.shinyapps.io/InsolvencyTestApp/')}>
                <FaBalanceScale size={150} />
                <p>Firm Insolvency Monitor</p>
                <small>View insolvency data</small>
              </div>
              <div className="indicator-card" onClick={() => alert('Currently Being Implemented..')}>
                <FaDollarSign size={150} />
                <p>Local Bank Price Monitor</p>
                <small>View local bank price monitor</small>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeModal === 'research' && <ResearchModal onClose={() => setActiveModal(null)} />}
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/research" element={<Research />} />
          <Route path="/knowledge-transfer" element={<KnowledgeTransfer />} />
          <Route path="/real-time-insolvencies" element={<RealTimeInsolvencies />} />
          <Route path="/firm-level-research" element={<FirmLevelResearch />} />
          <Route path="/data-page" element={<DataPage />} />
          <Route path="/our-team" element={<OurTeam />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

const MainApp: React.FC = () => (
  <Router>
    <ScrollToTop/>
    <App />
  </Router>
);

export default MainApp;
