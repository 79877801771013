import React, { useState, useRef } from 'react';
import './ResearchPage.css';

const FirmLevelResearch: React.FC = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const dataRef = useRef<HTMLDivElement>(null);
  const findingsRef = useRef<HTMLDivElement>(null);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    if (tab === 'data' && dataRef.current) {
      dataRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (tab === 'findings' && findingsRef.current) {
      findingsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="page-container">
      <h1>Using Web-Scraped Data to Measure Firm CSR</h1>
      <div className="tab-menu">
        <button className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`} onClick={() => handleTabClick('overview')}>
          Overview
        </button>
        <button className={`tab-button ${activeTab === 'data' ? 'active' : ''}`} onClick={() => handleTabClick('data')}>
          Data
        </button>
        <button className={`tab-button ${activeTab === 'findings' ? 'active' : ''}`} onClick={() => handleTabClick('findings')}>
          Findings
        </button>
      </div>

      <div className={`tab-content overview-content ${activeTab === 'overview' ? 'active' : ''}`}>
        <h2>Overview</h2>
        <p>Firm websites and job portals can be insightful sources for firm-level research. But how much information is available online? In a pilot study for the district of Siegen-Wittgenstein, we test availability and reliability of web data for firm-level research. Extracting information from the textual web data with different text mining techniques, we demonstrate the feasibility of information retrieval from unstructured data.</p>
      </div>

      <div ref={dataRef} className={`tab-content data-content ${activeTab === 'data' ? 'active' : ''}`}>
        <h2>Data</h2>
        <ul>
          <li>Websites of 4500 firms</li>
          <li>Job postings from selected regional job portals</li>
        </ul>
      </div>

      <div ref={findingsRef} className={`tab-content findings-content ${activeTab === 'findings' ? 'active' : ''}`}>
        <h2>Findings</h2>
        <ul>
          <li>Communication patterns of firms in Siegen-Wittgenstein regarding recruiting signals: Difference between size classes</li>
          <li>Large firms communicate more about their activities for employee well-being and early development measures</li>
          <li>SMEs show more CSR, Team-based, and Values-based communication</li>
        </ul>
        <div className="chart-container">
          <img src={`${process.env.PUBLIC_URL}/chart_2.png`} alt="Findings Chart" className="chart-image" />
        </div>
      </div>
    </div>
  );
};

export default FirmLevelResearch;
