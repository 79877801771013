import React, { useState, useRef } from 'react';
import './ResearchPage.css';
import { useNavigate } from 'react-router-dom';

const RealTimeInsolvencies: React.FC = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const developmentsRef = useRef<HTMLDivElement>(null);
  const findingsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    if (tab === 'developments' && developmentsRef.current) {
      developmentsRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (tab === 'findings' && findingsRef.current) {
      findingsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleOpenDashboardModal = () => {
    navigate('/dashboard', { state: { iframeSrc: 'https://mschmitt-resme.shinyapps.io/InsolvencyTestApp/' } });
  };

  return (
    <div className="page-container">
      <h1>Anatomy of Real-Time Insolvencies</h1>
      <div className="tab-menu">
        <button className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`} onClick={() => handleTabClick('overview')}>
          Overview
        </button>
        <button className={`tab-button ${activeTab === 'developments' ? 'active' : ''}`} onClick={() => handleTabClick('developments')}>
          Latest Developments
        </button>
        <button className={`tab-button ${activeTab === 'findings' ? 'active' : ''}`} onClick={() => handleTabClick('findings')}>
          Findings
        </button>
      </div>

      <div className={`tab-content overview-content ${activeTab === 'overview' ? 'active' : ''}`}>
        <h2>Overview</h2>
        <p>After the exceptional developments during the pandemic, insolvencies are currently increasing. While a macro view provides insights into general effects of the current crisis on firms and their financial vitality, RESME data enables us to generate more precise insights. Exploiting the information of official insolvency announcements, we generate real-time statistics and analyze current insolvency developments of different regions, industries, and family businesses.</p>
        <a onClick={handleOpenDashboardModal}>Explore our dashboard</a>
      </div>

      <div ref={developmentsRef} className={`tab-content developments-content ${activeTab === 'developments' ? 'active' : ''}`}>
        <h2>Latest Developments</h2>
        <ul>
          <li>XY% more insolvencies in June 2024 than in June 2023</li>
          <li>60% of all districts (Landkreise) display a higher number of insolvencies per 10000 firms than last year</li>
        </ul>
      </div>

      <div ref={findingsRef} className={`tab-content findings-content ${activeTab === 'findings' ? 'active' : ''}`}>
        <h2>Findings</h2>
        <ul>
          <li>Rising number of insolvencies</li>
          <li>Family businesses have a slightly lower risk of insolvency (measured as number of insolvencies per 10000 firms in the respective group)</li>
        </ul>
      </div>
    </div>
  );
};

export default RealTimeInsolvencies;
