import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

const Home: React.FC = () => {
  const navigate = useNavigate();

  const handleLinkClick = (path: string) => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate(path);
  };

  const handleOpenDashboardModal = () => {
    navigate('/dashboard', { state: { iframeSrc: 'https://resme-research.shinyapps.io/RESMEInsolvencyApp/' } });
  };

  return (
    <div className="home-container">
      <div className="top-section">
        <div className="left-pane">
          <img src={`${process.env.PUBLIC_URL}/wearesorry.jpg`} alt="Laptop with dashboard" className="left-image" />
          <div className="overlay-text">
            <h2>Real-Time Insolvency Monitor</h2>
            <p>By Region, Firm-Type and Ownership</p>
            <button onClick={handleOpenDashboardModal}>Explore Data</button>
          </div>
        </div>
        <div className="right-pane">
          <div className="right-top">
            <img src={`${process.env.PUBLIC_URL}/pexels-djordje-petrovic-590080-2102416.jpg`} alt="Discussion" className="right-image" />
            <div className="overlay-text-small" onClick={() => handleLinkClick('/knowledge-transfer')}>
              <h3>Measuring The Knowledge Transfer Of Universities</h3>
              <p>Learn more</p>
            </div>
          </div>
          <div className="right-bottom">
            <img src={`${process.env.PUBLIC_URL}/pexels-thisisengineering-3861952-dark.jpg`} alt="Working" className="right-image" />
            <div className="overlay-text-small" onClick={() => handleLinkClick('/data-page')}>
              <h3>The RESME Database</h3>
              <p>Real-time Regional and Firm-Level Information</p>
            </div>
          </div>
        </div>
      </div>

      <div className="what-we-do-section">
        <div className="what-we-do-header">
          <h2>RESME OVERVIEW</h2>
        </div>
        <div className="mission-statement">
          <p>
            The RESME data initiative, founded in 2020, continuously collects comprehensive regional data for research in the field of regional economics (RE) and for small and medium-sized enterprises (SME). Using modern data science methods, we collect and process data in real time to produce informative insights.
          </p>
        </div>
      </div>

      <div className="milestones-section">
        <div className="what-we-do-header">
          <h3>Milestones In the Development Of RESME</h3>
        </div>
        <div className="mission-chart">
          <img src={`${process.env.PUBLIC_URL}/chart-image_2.png`} alt="Mission Chart" />
        </div>
      </div>
    </div>
  );
};

export default Home;
