import React from 'react';
import { Link } from 'react-router-dom';
import './Common.css'; // Import the common CSS file

const researchData = [
  {
    title: 'Knowledge Transfer',
    about: 'How do higher education institutions transfer their knowledge to the regional economy? Exploiting soft information from website texts of higher education institutions we proxy their transfer activities and aim at enhancing traditional transfer measurements.',
    findings: [
      'Communication patterns of transfer activities of German higher education institutions: Text-based measurement uncovers preference for informal transfer.'
    ],
    link: '/knowledge-transfer',
    image: 'pexels-rdne-5922204.jpg'
  },
  {
    title: 'Anatomy of Real-Time Insolvencies',
    about: 'After the exceptional developments during the pandemic, insolvencies are currently increasing. RESME data enables us to generate more precise insights. Exploiting the information of official insolvency announcements, we generate real-time statistics and analyze current insolvency developments of different regions, industries, and family businesses.',
    latestDevelopments: [
      'XY% more insolvencies in June 2024 than in June 2023',
      '60% of all districts (Landkreise) display a higher number of insolvencies per 10000 firms than last year.'
    ],
    findings: [
      'Rising number of insolvencies.',
      'Family businesses have a slightly lower risk of insolvency (measured as number of insolvencies per 10000 firms in the respective group).'
    ],
    link: '/real-time-insolvencies',
    image: 'melinda-gimpel-9j8k3l9afkc-unsplash.jpg'
  },
  {
    title: ' Using Web-Scraped Data to Measure Firm CSR',
    about: 'Firm websites and job portals can be insightful sources for firm-level research. In a pilot study for the district of Siegen-Wittgenstein, we test the availability and reliability of web data for firm-level research.',
    data: [
      'Websites of 4500 firms',
      'Job postings from selected regional job portals'
    ],
    findings: [
      'Communication patterns of firms in Siegen-Wittgenstein regarding recruiting signals: Difference between size classes. Large firms communicate more about their activities for employee well-being and early development measures. SMEs show more CSR, Team-based, and Values-based communication.'
    ],
    link: '/firm-level-research',
    image: 'pexels-kevin-ku-92347-577585.jpg'
  }
];

const Research: React.FC = () => {
  return (
    <div className="page-container">
      <h1>Research</h1>
      <p>As a data and research initiative, we conduct and promote research in two broad fields: Economics and SME/Entrepreneurship.</p>
      
      <div className="research-grid">
        {researchData.map((research, index) => (
          <div className="research-card" key={index}>
            <img src={`${process.env.PUBLIC_URL}/${research.image}`} alt={research.title} className="research-image" />
            <h2>{research.title}</h2>
            <p>{research.about}</p>
            {research.latestDevelopments && (
              <>
                <h3>Latest Developments</h3>
                <ul>
                  {research.latestDevelopments.map((dev, i) => (
                    <li key={i}>{dev}</li>
                  ))}
                </ul>
              </>
            )}
            {research.findings && (
              <>
                <h3>Findings</h3>
                <ul>
                  {research.findings.map((finding, i) => (
                    <li key={i}>{finding}</li>
                  ))}
                </ul>
              </>
            )}
            <Link to={research.link}>Explore More</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Research;
